@import "node_modules/include-media/dist/include-media";

$breakpoints: (phone: 320px, tablet: 768px, desktop: 1100px);

$blue: #44a4dc;
$gray: #ededed;
$darkgray: #4a4a4a;

.translatable, #landing-header {
  margin: 0;
  padding: 0;
}

h1 {
  font-family: "Montserrat", sans-serif;
  color: #9c9c9c;
  font-size: 3em;
  line-height: 1em;
  font-weight: bolder;
  text-transform: uppercase;

  @include media(">=desktop") {
    min-width: 600px;
    max-width: 650px;
    font-size: 4em;
  }

  @include media("<=tablet") {
    font-size: 1.7em;
    margin-top: 3rem;
  }

  @include media(">tablet", "<desktop", "landscape") {
    font-size: 3em;
    max-width: 70%;
  }
}

h2 {
  font-family: Lora, serif;
  color: #9c9c9c;
  font-weight: normal;

  @include media(">=desktop") {
    min-width: 600px;
    max-width: 650px;
  }

  @include media("<tablet") {
    width: 70%;
    font-size: 1.1rem;
  }
  @include media(">tablet", "<desktop") {
    max-width: 70%;
  }
}

#landing-header {
  height: 680px;
  background-size: contain;
  position: relative;
  background: url("../img/monalisacovid.png") no-repeat 100px 280px, #ededed;

  @include media(">=desktop") {
    height: 800px;
    background: url("../img/monalisacovid.png") no-repeat right center, #ededed;
    background-size: contain;
  }

  @include media("<tablet") {
    height: 680px;
    padding: 30px;
    background: url("../img/monalisacovid.png") no-repeat 100px 280px, #ededed;
    background-size: contain;
  }

  @include media(">=tablet", "<desktop") {
    background: url("../img/monalisacovid.png") no-repeat 100% 200px, #ededed;
    background-size: contain;
  }
}

#landing-menu-open {
  margin-top: 30px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  font-family: "Montserrat", sans-serif;
  color: #9c9c9c;
  font-size: 1.3rem;
  display: none;

  @include media("<tablet") {
    flex-direction: column;
    margin: 0;
  }
}

#landing-menu-open a {
  cursor: pointer;
}

#landing-menu-open a:hover {
  font-weight: bolder;
}

.menu-open {
  @include media("<tablet") {
    max-width: 24px;
  }
}

#menu-most-common {
  width: 60%;
  height: 100%;
  @include media("<tablet") {
    width: 100%;
  }
}

#menu-supported {
  width: 40%;
  height: 100%;
  @include media("<tablet") {
    width: 100%;
  }
}

#landing-header-wrapper {
  max-width: 1100px;
  margin: 0 auto;

  @include media(">tablet", "<desktop") {
    padding: 20px;
  }
}

ul {
  margin: 0;
}

li {
  display: inline;
  margin: 0;
}

.container {
  padding: 40px 0;
  @include media("<desktop") {
    padding: 40px 30px;
  }
}

.mobile-compact-container {
  @include media("<tablet") {
    padding: 10px 10px !important;
  }
}

.content {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
}

.rows {
  display: flex;
  flex-direction: row;
}

.columns {
  display: flex;
  flex-direction: column;
}

.text {
  font-family: Lora, serif;
  font-size: 18px;
  word-wrap: break-word;
  color: #e4e2e2;
}

.text-dark {
  font-family: Lora, serif;
  font-size: 18px;
  word-wrap: break-word;
  color: $darkgray;
}

.darkgray {
  background-color: #4a4a4a;
}

#intro-countries {
  @include media("<tablet") {
    align-items: center;
  }
}

#intro-countries .text-content {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include media(">=desktop") {
    width: 90%;
  }
}

#language-selector li a {
  color: #9c9c9c;
  text-decoration: none;
}

//Images
#mapa-europa {
  object-fit: contain;
  margin-right: 2%;
  width: 70%;
}

#mapa-europa img {
  max-width: 100%;
}

.wrap {
  flex-wrap: wrap;
}

.disinformation-topic {
  cursor: pointer;
  font-family: Lora, serif;
  font-size: 20px;
  text-align: center;

  @include media("<tablet") {
    width: 48% !important;
    font-size: 16px;
    justify-content: space-between;
  }
}

.disinformation-topic img {
  height: 120px;
  @include media("<tablet") {
    height: 100px;
  }
}

.disinformation-topic-big {
  width: 256px;

  @include media(">=tablet", "<desktop"){
    width: 19%;
    margin: 1%;
  }
}

.disinformation-topic-small {
  width: 19%;
}

#most-common-disinformation {
  position: relative
}

#most-common-disinformation .rows {
  justify-content: space-between;
}

.mb-2 {
  margin-bottom: 2rem;
}

.baseline {
  align-items: baseline;
}

.items-center {
  align-items: center;
}

#most-common-disinformation .headline.desktop {
  @include media("<tablet") {
    display: none;
  }
}

#most-common-disinformation .headline.mobile {
  margin-bottom: 12px;
  @include media(">=desktop") {
    display: none;
  }
}

#most-common-disinformation .headline {
  font-family: 'Montserrat', sans-serif;
  text-align: right;
  color: gray;
  line-height: 34px;
  font-size: 34px;
  font-weight: bolder;

  @include media(">=desktop"){
    max-width: 30%;
    margin: 0;
  }

  @include media(">=phone", "<desktop") {
    margin-bottom: 30px;
  }

  @include media("<=tablet") {
    max-width: 100%;
    width: 100%;
    margin-bottom: 20px;
  }
}

#graphics .headline {
  font-family: Montserrat, sans-serif;
  font-size: 1.7rem;
  font-weight: bolder;
  line-height: 0.93;
  text-align: right;
  color: $darkgray;
  @include media(">=desktop") {
    font-size: 4rem;
  }
}

#graphics .text-content {
  display: flex;
  justify-content: flex-end;
  text-align: right;
}

#graphics #political-text {
  @include media(">=desktop") {
    width: 50%;
  }
}

.graph-legend {
  max-width: 100%;
}

.graphics-box {
  display: flex;
  flex-direction: column;
}

#container_leyenda {
  alignment: right;
  width: 392px;
  height: 23px;
  object-fit: contain;
}

iframe {
  border: none;
}

.blue {
  background: $blue;
}

.gray {
  background: $gray;
}

#container-leyenda {
  display: flex;
  align-content: unset;
  justify-content: flex-end;
}

#topbar {
  font-family: "Montserrat", sans-serif;
  color: #9c9c9c;
  display: flex;
  justify-content: space-between;

  @include media(">=desktop") {
    padding-top: 30px;
  }

  @include media("<tablet") {
    align-items: center;
  }
}

#menu-most-common-wrapper {
  max-width: 650px;
  margin: 20px auto;
  @include media("<tablet") {
    padding: 40px;
    margin: 0;
  }
}

.supported-logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.menu-logo-wrapper {
  margin: 10px;
  display: flex;
  width: 100%;
}

.half {
  width: 44%;
}

#menu-supported-wrap {
  width: 80%;
  margin: 84px auto 0 auto;
  color: white;
}

#menu-options a {
  display: block;
  color: #9c9c9c;
  text-decoration: none;
  margin-bottom: 6px;
}

#menu-options a:first-of-type {
  margin-top: 20px;
}

footer {
  font-family: "Montserrat", sans-serif;
}

footer .footer-logos {
  padding: 80px;
}

.footer-logo {
  width: 33%;

  @include media("<tablet") {
    margin-bottom: 26px;
  }

  @include media(">=tablet", "<desktop") {
    width: auto;
    margin: 12px;
  }
}

.footer-logos-wrapper {
  flex-wrap: wrap;
  justify-content: end;
}

.joint, .supported, .footer-logos-wrapper {
  display: flex;

  @include media("<tablet") {
    display: block;
  }

  @include media(">=tablet", "<desktop") {
    display: flex;
  }
}

.joint {
  margin-bottom: 40px;
}

.joint, .supported, {
  justify-content: end;
}

.joint p, .supported p {
  width: 30%;
  @include media("<tablet") {
    width: 100%;
  }

  @include media(">=tablet", "<desktop") {
    width: 100%;
  }
}

footer .end {
  min-height: 120px;
  background: $blue;
  display: flex;
  justify-content: center;
  align-items: center;
}

.disinformations-detail {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: $blue;
  display: none;
}

.disinformations-detail-content {
  width: 50%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  color: $gray;

  @include media("<tablet") {
    width: 80%;
  }
}

#explanation-content a {
  color: $gray;
}

.disinformations-detail-content header {
  display: flex;
  justify-content: space-between;
}

.disinformations-detail-content header h3 {
  margin: 0;
  font-family: "Lora", serif;
  font-size: 2rem;
}

.disinformations-detail-content p {
  font-size: 1.1rem;
}

.disinformations-detail {
  font-family: Lora, serif;
}

.columns-in-mobile {
  @include media("<=desktop") {
    flex-direction: column !important;
    align-items: center;
  }
}

.mobile {
  @include media(">tablet") {
    display: none;
  }
}

.desktop {
  @include media("<=tablet") {
    display: none;
  }
}

.tablet{
  @include media (">desktop"){
    display: none;
  }
}

.desktop-not-tablet {
  @include media("<desktop") {
    display: none;
  }
}

.country-graphic {
  height: 400px;
  @include media(">=desktop") {
    height: 600px;
  }
}

.license {
  width: 50%;
  font-family: "Montserrat", sans-serif;
  color: white;
  text-align: center;
  text-decoration: none;

  @include media("<tablet") {
    width: 80%;
  }
}

.download-button {
  background: $blue;
  color: white;
  padding: 20px 20px;
  font-family: "Lora", serif;
  display: inline-block;
  margin-top: 20px;
  text-decoration: none;

  @include media("<tablet") {
    position: absolute;
    bottom: 100px;
    display: inline-block;
  }
}

#gargling .genially-view-slide-container {
  background: $darkgray;
}
